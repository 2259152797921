import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="改善の進め方 - パフォーマンス" mdxType="SEO" />
    <PageTitle title="改善の進め方" enTitle="Optimization" mdxType="PageTitle" />
    <p>{`パフォーマンス改善はユーザーの利用体験を改善します。「パフォーマンス」にはページの表示速度だけでなく、閲覧中の操作のレスポンス、低速回線やオフライン時の動作も含まれます。`}</p>
    <p>{`パフォーマンスを改善するためには、計測・目標設定・改善計画・実装・効果検証(計測)のプロセスを繰り返すことが重要です。実際にパフォーマンスを改善した`}<a parentName="p" {...{
        "href": "https://developers.cyberagent.co.jp/blog/archives/30577/"
      }}>{`Amebaマンガの事例`}</a>{`が参考になります。`}</p>
    <p>{`パフォーマンス改善の進め方は計測・調査し、問題となる箇所の計算量をいかに効率的にできるかという点ですべてのアプリケーションに通じます。ここではWebアプリケーションを例に解説します。`}</p>
    <h2 {...{
      "id": "計測",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E8%A8%88%E6%B8%AC",
        "aria-label": "計測 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`計測`}</h2>
    <p>{`まず最初に行うべきは計測です。数値が取れていないと現状把握ができないため、強み・弱みもわからず目標設定ができません。また修正した内容が良かったのか、悪かったのかも検証できません。`}</p>
    <p>{`計測をする際には様々なツールがありますが、収集されるデータは大きく分けてフィールドデータとラボデータがあります。`}</p>
    <p>{`フィールドデータは実際に利用者が体験したパフォーマンスであり、真の値に近いものと考えられるでしょう。ただし、実際に取得される環境は様々であったり、エラーが発生した場合は送信されなかったりするためデバッグには向いていません。`}</p>
    <p>{`ラボデータはパフォーマンス計測に影響するパラメーターのいくつかを固定し、計測するためパフォーマンス遅延の原因調査や定点観測に向いています。ただしそのサイトのパフォーマンスのごく一部しか表していないため、取得したパフォーマンス値が良い場合でもサイト全体のパフォーマンスが良い保証はないことに注意が必要です。`}</p>
    <p>{`ユーザーの利用体験を向上させるには実際のパフォーマンスを見る必要があるので、基本的にはフィールドデータが必要です。これはビジネス指標との相関を見るうえでも重要です。問題点を発見・特定、改善効果を具体的に調べたい場合には、各種パラメーターを固定できるラボツールを使うと良いしょう。`}</p>
    <p>{`どのようにツールを使い分けるかはサイトの特性にもよります。理想的にはどちらのデータも取得しモニタリングすると良いですが、ページの種類が利用者の属性が多様な場合にはフィールドデータがより意味をなしますし、ページ種類がある程度管理できる場合はラボツールで代表的なページ群を監視しておけば十分かもしれません。`}</p>
    <p>{`パフォーマンスを計測するツールの詳細については、以下のスライドをご覧ください。`}</p>
    <div style={{
      "left": "0",
      "width": "100%",
      "height": "0",
      "position": "relative",
      "paddingBottom": "56.1972%",
      "marginTop": "32px"
    }}><iframe src="https://speakerdeck.com/player/66ee8ba24c9d4892acd1996e65cad9a1?slide=15" style={{
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%",
        "position": "absolute",
        "border": "0"
      }} allowFullScreen scrolling="no" allow="encrypted-media;"></iframe></div>
    <h2 {...{
      "id": "目標設定",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%9B%AE%E6%A8%99%E8%A8%AD%E5%AE%9A",
        "aria-label": "目標設定 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`目標設定`}</h2>
    <p>{`ある程度計測ができた段階で目標設定に移ります。目標設定をするときには、競合と比較する場合と定義された目標値に準じる場合があります。ニュースサイトやECサイトのように競合とほぼ同様のサービスを提供しており、かつパフォーマンスがビジネス指標に大きく影響しそうな場合には、競合も同時に計測しましょう。競合より20%速いと有意に差が出るという研究もあります。`}</p>
    <p>{`定義された目標値に従う方法もまた有効です。最低限の品質を提供しマイナスを減らせます。基準値があるため劣化の検知も容易です。代表的なものにWeb Vitalsがあり、LighthouseやSpeedCurveなど様々なツールも対応しています。毎年アップデートが計画されているので、それに従っていく方法は有効です。`}</p>
    <p>{`ただし各サービスに固有の重要指標がある場合には個別に計測、目標設定する必要があります。例えば、いいねや購読ボタンなどのアクションにたどり着くまでのフローが重要な場合には、そこまでのステップ数や経過時間を目標として設定しておく必要があるでしょう。`}</p>
    <p>{`パフォーマンス目標設定については、以下のスライドをご覧ください。`}</p>
    <div style={{
      "left": "0",
      "width": "100%",
      "height": "0",
      "position": "relative",
      "paddingBottom": "56.1972%",
      "marginTop": "32px"
    }}><iframe src="https://speakerdeck.com/player/bef0cf88b4ad472fa49c38674e0d0083?slide=32" style={{
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%",
        "position": "absolute",
        "border": "0"
      }} allowFullScreen scrolling="no" allow="encrypted-media;"></iframe></div>
    <h2 {...{
      "id": "改善計画",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%94%B9%E5%96%84%E8%A8%88%E7%94%BB",
        "aria-label": "改善計画 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`改善計画`}</h2>
    <p>{`計測と目標設定ができたら、実装へ入る前に改善計画を作成します。ここでは何がボトルネックで、どういう方法で解決していくか記録しておくと良いでしょう。その際には複数の解決オプションをリストアップし、なぜそのプランが選択されたのか記載しておくと、効果検証の際に役立ちます。
狙い通り改善できればその手順は次回の改善にも活かせますし、もしうまくいかなかった場合には他のオプションも含めて再度改善の手を打つことができます。`}</p>
    <h2 {...{
      "id": "実装",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%AE%9F%E8%A3%85",
        "aria-label": "実装 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`実装`}</h2>
    <p>{`計画ができたら実装を進めます。リスクや影響度の大きさに応じて段階的にリリースすると良いでしょう。この際にはできればサーバ側で判定できる手法を利用することをおすすめします。クライアントサイドで判定すると表示が遅延したり、レイアウトシフトが発生したりと正確に影響を測れないことがあるためです。`}</p>
    <p>{`実装に致命的な問題がないか把握するためには、Blue-Greenでデプロイすると良いでしょう。セッションごとの効果を検証したい場合には、HTTP HeadersやCookieなどを利用してサーバから配信する内容を変更すると良いでしょう。`}</p>
    <h2 {...{
      "id": "効果検証",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%8A%B9%E6%9E%9C%E6%A4%9C%E8%A8%BC",
        "aria-label": "効果検証 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`効果検証`}</h2>
    <p>{`リリースされ、ある程度結果が見られるようになったら、効果検証をします。検証する際には最初に設定した計測ツールで変化を見ると効率が良いでしょう。うまく効果が出た場合には、目標値を上方修正しても良いでしょう。うまく効果が出なかった場合には改善計画を再調整して、実装し直してください。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      